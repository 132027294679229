body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.USER_DETAILS{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.USER_DETAILS div{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.TOTAL{
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  margin-top: 1rem;
}

.TOTAL div{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.TOTAL > div span{
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.TOTAL > div span b{
  margin-right: 1rem;
}

.ProductIMG{
  /* border: 1px solid red; */
}
.ProductIMG img{
  margin: 0.3rem;
}

.ShopName{
  
}
.ShopName input{
  width: 100%;
  border: 1px solid #3636369c;
  border-radius: 4px;
  padding: 0.4rem;
}



.ATTRIBUTE_OPTIONS{
  margin-top: 1rem;
  /* border: 2px solid rgba(128, 128, 128, 0.226); */
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.425);
  width: 100%;
  padding-top: 1rem;
  border-radius: 5px;
  position: relative;
  left: 30px;
}
.ATT_REM_BTN ,.ATT_REM_BTN2{
  text-align: right;
}
.ATT_REM_BTN button{
  border: none;
  padding: 0.2rem 0.5rem;
  background: #282b2b;
  border: 1px solid #fff;
  margin-bottom: 0.5rem;
  color: white;
  outline: none;
  border-radius: 4px;
  transition: 300ms all ease;
}
.ATT_REM_BTN2 button{
  border: none;
  padding: 0rem 0.5rem;
  background: #282b2b;
  border: 1px solid #fff;
  margin-bottom: 0.5rem;
  color: white;
  outline: none;
  border-radius: 4px;
  transition: 300ms all ease;
}
.ATT_REM_BTN button:hover, .ATT_REM_BTN2 button:hover{
  background: #fff;
  font-weight: bold;
  border: 1px solid #282b2b;
  color: #282b2b;
}
.FONT_WEIGHT{
  font-weight: bold;
}
.FONT_WEIGHT2{
  font-size: 1.05rem;
  font-weight: bold;
}
.FONT_SIZE{
  font-weight: bold;
  font-size: 1rem;
}
.DATA_SAVE{
  display: flex;
  justify-content: right;
  margin-top: 2rem;
}
.UPLOAD_Container{
  display: grid;
  grid-template-columns: auto 200px;
  gap: 1rem;
  margin-bottom: 2rem;
}
.UPLOADED_FILES{
  height: 200px;
  padding: 0.7rem;
  display: flex;
  flex-direction: row;
  box-shadow:inset 3px 3px 10px rgba(128, 128, 128, 0.219);
  overflow-x: scroll;
  position: relative;
  border-radius: 5px;
}
.UPLOADED_FILES p{
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(128, 128, 128, 0.445);
  position: absolute;
  top:45%;
  left:40%;
  z-index: 1;
}
.UPLOADED_FILES div {
  width:170px;
  height: 100%;
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.466);
  margin-right: 1rem;
  position: relative;
  z-index: 3;
}
.REMOVE{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.836);
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  background: #282b2b70;
  opacity: 0;
  transition: 300ms all ease;
}
.REMOVE:hover{
  opacity:1;
}
.UPLOAD_BTN{
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.466);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UPLOAD_BTN:hover{
  background: rgba(128, 128, 128, 0.13);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.425);
}
.UPLOAD_BTN label{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.UPLOAD_BTN label span{
  font-size: 1.1rem;
  font-weight:bold;
  margin-top: 1rem;
  color: black;
}
.OrdrStatus{
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
}
.OrdrStatus h2{
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
} 
.OrdrStatus select{
  border: 1px solid #282b2b;
  padding: 0.3rem;
  border-radius: 4px;
  font-size: 0.9rem;
  width: 60%;
}

.ParticipantsColumn{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.ParticipantsColumn b{
  font-weight: 500;
  color: #000;
  font-size: 16px;
}
.ParticipantsColumn i{
  color: #000;
}

.Status{
  background: #198754;
  color: #fff;
  font-weight: 600;
  padding: 1px 5px;
  border-radius: 4px;
}

.Filters{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow:inset 0px 0px 5px #363636b2; */
  padding: 10px;
  border-radius: 5px;
}
.Filters > div:first-child{
  width: 70%;
  padding: 5px 0;
  /* border: 1px solid red; */
}

.Filters input{
  border: 1px solid #363636b9;
  border-radius: 4px;
  padding: 5px;
  width: 300px;
  margin-right: 50px;
}
.Filters button{
  color: #fff;
  background: #000;
  border:none;
  border-radius: 4px;
  font-size: 17px;
  padding:4px  10px;
  transition: 300ms all ease;
}
.Filters button:hover{
  background: rgba(0, 0, 0, 0.651);
}
.ProductImage img {
  height: 30px;
}
.assignDriverModule{
  padding:15px 10px;
  display: flex;
  gap: 50px;
  display: flex;
  /* justify-content: space-between */
}
.assignDriverModule div {
  display: flex;
  gap: 20px;
  align-items: center;
}
.assignDriverModule label{
  font-size: 17px;
  font-weight: 600;
  color: #363636af;
}
.assignDriverModule select{
  border: 1px solid #363636b9;
  border-radius: 4px;
  padding: 5px;
  width: 300px;
}

.AddressModal{
  margin: 10px;
  margin-top: 25px;
  width: 500px;
}
.AddressModal p {
  font-size: 16px;
  color: #000;
  border-bottom:1px solid #36363663 ;
  padding-bottom: 10px;
}
.AddressModal p b {
  margin-right: 20px;
}